<template>
  <v-card
    :loading="loading"
    height="100%"
    flat
    tile
  >
    <v-row
      v-if="loading"
      class="fill-height"
      align-content="center"
      justify="center"
    >
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      >
        <v-icon>mdi-google</v-icon>
      </v-progress-circular>
    </v-row>

    <iframe v-else :src="editUrl" frameborder="0"></iframe>

    <v-btn
      @click="$router.push(referrer)"
      :loading="loading"
      color="primary"
      absolute
      bottom
      right
    >
      <v-icon>mdi-check-circle-outline</v-icon>
      {{$t('finish_editing')}}
    </v-btn>
  </v-card>
</template>

<style scoped>
  iframe {
    width: 100%;
    height: 100%;
  }
</style>

<script>

import { mapActions } from 'vuex'

import ContentActions from '@/store/content/actions/actions-types'
import EntryActions from '@/store/content/entry/actions-types'

export default {
  name: 'EditGDoc',

  data() {
    return {
      loading: true,
      editUrl: "",
      permissionId: "",
      referrer: null
    }
  },

  created() {
    window.addEventListener('beforeunload', this.handler, {capture: true})
  },

  beforeRouteLeave(to, from, next) {
    this.handler()

    next()
  },

  mounted() {
    this.fetchData()
    this.referrer = this.$route.query.from
  },

  methods: {
    ...mapActions('content/entry', [
      EntryActions.GET_ENTRY_DATA,
    ]),

    ...mapActions('content/actions', [
      ContentActions.EDIT_GDOC,
      ContentActions.FINISH_EDIT_GDOC,
    ]),

    fetchData() {
      this.loading = true

      this[EntryActions.GET_ENTRY_DATA](this.$route.params.nodeId)
        .then((nodeData) => {
          this[ContentActions.EDIT_GDOC](this.$route.params.nodeId)
            .then((result) => {

              if (nodeData.extension === 'gcolab') {
                window.location.href = result.editUrl

                return
              }

              this.permissionId = result.permissionId
              this.editUrl = result.editUrl

              this.loading = false
            })
        })

    },

    handler(event) {
      event?.stopPropagation()

      this[ContentActions.FINISH_EDIT_GDOC]({nodeId: this.$route.params.nodeId, params: {permission_id: this.permissionId}})
      .then(() => window.close())
    }
  },
}

</script>
